// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-clock-app-js": () => import("./../../../src/pages/portfolio/clock-app.js" /* webpackChunkName: "component---src-pages-portfolio-clock-app-js" */),
  "component---src-pages-portfolio-insure-js": () => import("./../../../src/pages/portfolio/insure.js" /* webpackChunkName: "component---src-pages-portfolio-insure-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-rdw-joinery-js": () => import("./../../../src/pages/portfolio/rdw-joinery.js" /* webpackChunkName: "component---src-pages-portfolio-rdw-joinery-js" */),
  "component---src-pages-portfolio-typemaster-js": () => import("./../../../src/pages/portfolio/typemaster.js" /* webpackChunkName: "component---src-pages-portfolio-typemaster-js" */),
  "component---src-pages-portfolio-vcr-fulfillment-js": () => import("./../../../src/pages/portfolio/vcr-fulfillment.js" /* webpackChunkName: "component---src-pages-portfolio-vcr-fulfillment-js" */)
}

